 
import  jwt_decode  from "jwt-decode"; 
import { useState,useEffect } from 'react';
// To federated sign in from Google
const SignInWithGoogleSDK = () => {

    const [login, setLogin] = useState(false);
    const [data, setData] = useState({
        isSignedIn: false,
        name: '',
        email: '',
        token: '',
        image: '',
        ProviderId: 'Google'});
    const [picture, setPicture] = useState('');

    const responseGoogle = (response) => {
       // console.log(response.credential);
        var output = jwt_decode(response.credential);
        
        setData(output);
        setPicture(output.picture);
        if (response.credential) {

            const googleresponse = {
                isSignedIn: true,
                name: output.name,
                email: output.email,
                token: output.sub,
                image: output.picture,
                ProviderId: 'Google'
            };
            setLogin(true);
            localStorage.setItem('user', JSON.stringify(googleresponse));
            setData(googleresponse);
           // console.log(googleresponse);
            document.getElementById("divGoogle").hidden = true;
           window.location.href="/profile";
        } else {
            setLogin(false);
            document.getElementById("divGoogle").hidden = false;
        }
    }
    const handleLoginFailure = error => {
        console.log("Login Failure ", error);
    }
    useEffect(() => {
        window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENTID,
            callback: responseGoogle
        }
        );
        if (document.getElementById("divGoogle")) {
            window.google.accounts.id.renderButton(
                document.getElementById("divGoogle"), { theme: "outline", size: "medium", width: "170" });
        
            window.google.accounts.id.renderButton(
                document.getElementById("divSmGoogle"), { theme: "outline", size: "medium", width: "170" });
        }
    }, []); 
    const handleLoginSuccess = (response) => {
        console.log("Login Success ", response);
        
    }


    return (<div>

        <div id="divGoogle" className="px-2 text-center d-none d-md-inline-block"></div> 
    
        <div id="divSmGoogle" className="px-2 text-center d-inline-block d-md-none"></div> 
       </div >
    );
}
export default SignInWithGoogleSDK;